import {api} from '@src/api/api'
import {ApiResponse} from "@src/store/ducks/user/types";
import {deleteHistoryAsync} from "@src/store/ducks/orders/thunks";

export const OrdersApi = {
  getBusPlaces(id:number) {
    return api.get('/v1/trips/getBusPlaces', {trip_id:id})
  },
  getParams() {
    return api.get('/v1/orders/get/params')
  },
  getHistory(status:number | null, id:number|null, page:number|null) {
    return api.get('/v1/orders/get', {user_id:id, status:status, page:page})
  },
  postOrder(data:any) {
    return api.post('/v1/orders/create', data)
  },
  getPdfAct(id:number) {
    return api.get('/v1/orders/getPdfAct', {order_id:id})
  },
  getPdfReport(id:number) {
    return api.get('/v1/orders/getPdfReport', {order_id:id})
  },
  getFilesHistory(orderId: string) {
    return api.get<ApiResponse>(`/v1/orders/${orderId}/files`,{} )
  },
  changeHistoryFilesTitle(orderId: string, id: string, title:string) {
    return api.post<ApiResponse>(`/v1/orders/${orderId}/files/${id}/update`,{title:title})
  },
  // downloadFilesHistory(orderID: string, id:string) {
  //   return api.get<ApiResponse>(`/v1/orders/${orderID}/files/${id}/download`,{}, {responseType: 'arraybuffer'} )
  // },
  loadHistoryFiles(orderId: string, formdata:any) {
    return api.post<ApiResponse>(`/v1/orders/${orderId}/files/upload`, formdata, {},{'Content-Type': 'multipart/form-data'})
  },
  deleteHistory(orderId: string, touristId:string) {
    return api.post<ApiResponse>(`/v1/orders/${orderId}/tourist/delete`, {'tourist_id' : touristId}, {},{})
  },
  getHistoryNewArrCity(orderId:string) {
    return api.get<ApiResponse>(`/v1/orders/${orderId}/cities`, {}, {})
  },
  updateHistory(orderId:string, changedObj: any) {
    return api.post<ApiResponse>(`/v1/orders/${orderId}/tourist/update`, changedObj,{}, {})
  },
  updateHistoryPlaces(orderId:string, changedObj: any) {
    return api.post<ApiResponse>(`/v1/orders/${orderId}/places/update`, changedObj,{}, {})
  },
  deleteHistoryFiles(orderId:string, filesId: string) {
    return api.post<ApiResponse>(`/v1/orders/${orderId}/files/${filesId}/delete`, {},{}, {})
  },
}



