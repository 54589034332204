import {api} from '@src/api/api'
import {ApiResponse, UserType} from '@src/store/ducks/user/types'


export const userApi = {
  getMe(id: number) {
    return api.get<ApiResponse>('/v1/profile/get', { user_id:id })
  },
  changeMe(user:UserType) {
    return api.post<ApiResponse>('/v1/profile/update', user)
  },
  changeImage(formData:any) {
    return api.post<ApiResponse>('/v1/profile/update', formData,{}, {'Content-Type': 'multipart/form-data'} )
  },
  getAvailablePlaces(id:number) {
    return api.get('/v1/user/available/places', {user_id:id})
  },
  loadNotification(formData:any) {
     return api.get<ApiResponse>('/v1/profile/notifications', formData,{})
  },
  deleteNotification(id:number) {
    return api.post<ApiResponse>('/v1/profile/notifications/delete', {id : id})
  },
  loadImage(formData:any) {
    // return api.post<ApiResponse>('/v1/profile/update', formData,{}, {'Content-Type': 'multipart/form-data'} )
  },
}
