import React, {FC, useEffect, useState} from "react";
import cn from "classnames";
import style from '@src/pages/History/HistoryFiles/HistoryFiles.module.scss'
import {HistoryFilesElem} from "@src/pages/History/HistoryFiles/HistoryFilesElem";
import {Button} from "@src/components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {UserType} from "@src/store/ducks/user/types";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {OrdersHistoryElemType, OrdersHistoryFilesType} from "@src/store/ducks/orders/types";
import {loadHistoryFilesAsync} from "@src/store/ducks/orders/thunks";

type HistoryFilesProps = {
    dataHistoryFiles: OrdersHistoryElemType,
};


export const HistoryFiles:FC<HistoryFilesProps> = ({dataHistoryFiles}) => {
    const dispatch = useDispatch<AppDispatch>()

    const data: UserType = useSelector(selectUserInfo)
    const [historyArr, setHistoryArr] = useState<OrdersHistoryFilesType[]>([])
    const onChangeHandler = (e: any) => {
        const formData = new FormData();
        formData.append("user_id", String(data.id));
        formData.append("file", e.target.files[0]);
         dispatch(loadHistoryFilesAsync(String(dataHistoryFiles.id),formData))
    };

    useEffect(() => {
        setHistoryArr(dataHistoryFiles.files)
    }, [dataHistoryFiles]);

    return (
        <div className={style.historyFiles}>
            <div className={style.historyFiles__title}>{'Файлы'}</div>
            <div className={style.historyFiles__list}>
                {historyArr && historyArr.map((item:OrdersHistoryFilesType) => {
                    return <HistoryFilesElem key={item.id} data={item} orderId={dataHistoryFiles.id}/>
                })}
            </div>
            <div className={style.historyFiles__load}>
                <Button className={cn('btn', 'btn-second',style['btn-load'])}>
                    <label htmlFor="fileInput">
                        <input id="fileInput" type="file" onChange={onChangeHandler}/>
                        Загрузить Файл
                    </label>
                </Button>
                <div className={style['historyFiles__load-description']}>
                    <span>Поддерживаемые форматы: </span>
                    <span>pdf, xls, xlsx, doc, docx, jpg, png</span>
                </div>
            </div>
        </div>
    )
}

