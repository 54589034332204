import React from "react";


export const DeleteHistory = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375C2.5 4.72018 2.77982 5 3.125 5H16.875C17.2202 5 17.5 4.72018 17.5 4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z"
                fill="#E83434"/>
            <path
                d="M7.5 8.125V13.125C7.5 13.4702 7.77982 13.75 8.125 13.75C8.47018 13.75 8.75 13.4702 8.75 13.125V8.125C8.75 7.77982 8.47018 7.5 8.125 7.5C7.77982 7.5 7.5 7.77982 7.5 8.125Z"
                fill="#E83434"/>
            <path
                d="M11.25 8.125V13.125C11.25 13.4702 11.5298 13.75 11.875 13.75C12.2202 13.75 12.5 13.4702 12.5 13.125V8.125C12.5 7.77982 12.2202 7.5 11.875 7.5C11.5298 7.5 11.25 7.77982 11.25 8.125Z"
                fill="#E83434"/>
            <path
                d="M5 16.25V4.375C5 4.02982 4.72018 3.75 4.375 3.75C4.02982 3.75 3.75 4.02982 3.75 4.375V16.25C3.75 16.7678 4.11612 17.1339 4.11612 17.1339C4.48223 17.5 5 17.5 5 17.5H15C15.5178 17.5 15.8839 17.1339 15.8839 17.1339C16.25 16.7678 16.25 16.25 16.25 16.25V4.375C16.25 4.02982 15.9702 3.75 15.625 3.75C15.2798 3.75 15 4.02982 15 4.375V16.25H5Z"
                fill="#E83434"/>
            <path
                d="M6.79918 1.79917C6.25 2.34835 6.25 3.125 6.25 3.125V4.375C6.25 4.72018 6.52982 5 6.875 5C7.22018 5 7.5 4.72018 7.5 4.375V3.125C7.5 2.86612 7.68306 2.68306 7.68306 2.68306C7.86612 2.5 8.125 2.5 8.125 2.5H11.875C12.1339 2.5 12.3169 2.68306 12.3169 2.68306C12.5 2.86612 12.5 3.125 12.5 3.125V4.375C12.5 4.72018 12.7798 5 13.125 5C13.4702 5 13.75 4.72018 13.75 4.375V3.125C13.75 2.34835 13.2008 1.79917 13.2008 1.79917C12.6517 1.25 11.875 1.25 11.875 1.25H8.125C7.34835 1.25 6.79918 1.79917 6.79918 1.79917Z"
                fill="#E83434"/>
        </svg>
    )
}