import {OrdersApi} from "@src/api/orders-api";
import {AppDispatch} from '@src/store/store'
import {
    setCurrentBusPlaces,
    setDeleteHistoryData,
    setHistoryData,
    setHistoryDataFiles,
    setHistoryDataFilesNew,
    setHistoryDeleteFiles,
    setHistoryNewArrCity,
    setHistoryNewBusPlaces,
    setHistoryUpdate,
    setOrderId,
    setOredrsParams
} from "@src/store/ducks/orders/reducer";
import {setSuccessPage, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import {setBackError} from "@src/store/ducks/auth/reducer";
import {keysToCamelCase} from "@src/lib/utils";
import * as fs from "node:fs";
import {getCookie} from "@src/store/ducks/auth/thunks";
import {userApi} from "@src/api/user-api";
import {setDeleteNotification} from "@src/store/ducks/user/reducer";
import {fetchMe} from "@src/store/ducks/user/thunks";


export const getBusPlacesAsinc = (id: number,orderId?: number, history?:boolean) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.getBusPlaces(id)
        if (history) {
            dispatch(setHistoryNewBusPlaces({orderId: orderId, result:result}))
        } else {
            dispatch(setCurrentBusPlaces(result))
        }
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const getParamsAsinc = () => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.getParams()
        dispatch(setOredrsParams(result))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const getHistoryAsinc = (status: number | null, id: number | null, page: number | null) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.getHistory(status, id, page)
        dispatch(setHistoryData({result, status}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
        /*    dispatch(setIsInitialized())*/
    }
}

export const postOrder = (data: any) => async (dispatch: AppDispatch) => {
    const result = await OrdersApi.postOrder(data)
    try {
        const cookieId = getCookie('user-id')
        dispatch(setOrderId(result.result.orderId))
        dispatch(setTourFormalisationOpen(false));
        dispatch(setSuccessPage(true));
        dispatch(setBackError(null));
        if(cookieId) {
            dispatch(fetchMe(+cookieId))
        }

    } catch (err: any) {
        dispatch(setBackError(keysToCamelCase(result[0])))
    } finally {

    }
}

export const getPdfActAsinc = (id: number) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.getPdfAct(id)
        const url = result.file;

        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        link.download = 'document.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
        /*    dispatch(setIsInitialized())*/
    }
}

export const getPdfReportAsinc = (id: number) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.getPdfReport(id)
        const url = result.file;
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        link.download = 'document.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const getFilesHistoryAsync = (orderId: string) => async (dispatch: AppDispatch) => {
    try {
        const result: any = await OrdersApi.getFilesHistory(orderId)
        dispatch(setHistoryDataFiles({orderId: orderId, files: result.files}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const downloadImagesHistoryAsync = (orderId: string, id:string, title:string) => async (dispatch: AppDispatch) => {
    try {
        fetch(`${process.env.REACT_APP_API_URL}/v1/orders/${orderId}/files/${id}/download`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getCookie('token')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('File download failed');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${title}`; // Use the correct file name from the backend
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading file:', error));
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const changeHistoryFilesTitleAsync = (orderId: string, id: string, title:string) => async (dispatch: AppDispatch) => {
    try {
        const result: any = await OrdersApi.changeHistoryFilesTitle(orderId, id, title)
        if(result.success) {
            dispatch(getFilesHistoryAsync(orderId))
        }
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const loadHistoryFilesAsync = (orderId: string,formData: any) => async (dispatch: AppDispatch) => {
    try {
        const result:any = await OrdersApi.loadHistoryFiles(orderId,formData)
        dispatch(setHistoryDataFilesNew({orderId: orderId,file:result.file}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const deleteHistoryAsync = (orderId: string, touristId:string) => async (dispatch: AppDispatch) => {
    try {
        const result:any = await OrdersApi.deleteHistory(orderId,touristId)
        if(result.success) {
            dispatch(setDeleteHistoryData({orderId,touristId}))
        }
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const getHistoryNewArrCityAsync = (orderId: string) => async (dispatch: AppDispatch) => {
    try {
        const result: any = await OrdersApi.getHistoryNewArrCity(orderId)
        const tripRoutes = result.tripRoutes
        dispatch(setHistoryNewArrCity({orderId:orderId, cityArr: tripRoutes}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}
export const updateHistoryAsync = (orderId: string, changedObj: any) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.updateHistory(orderId, changedObj)
        dispatch(setHistoryUpdate({orderId:orderId, result: result}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const updateHistoryPlacesAsync = (orderId: string, changedObj: any) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.updateHistoryPlaces(orderId, changedObj)
        console.log(result)
        dispatch(setHistoryUpdate({orderId:orderId, result: result}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}

export const deleteHistoryFilesAsync = (orderId: string, filesId: string) => async (dispatch: AppDispatch) => {
    try {
        const result = await OrdersApi.deleteHistoryFiles(orderId, filesId)
        console.log(result)
        dispatch(setHistoryDeleteFiles({orderId:orderId, filesId: filesId}))
    } catch (err: any) {
        if (err?.response?.status === 401) {
        }
    } finally {
    }
}






