import {Title} from "@src/components/Title/Title";
import {TourSvg} from "@src/components/AllSvg/ToursSvg";
import {TourTable} from "@src/pages/Tours/TourChoise/components/TourTable/TourTable";
import {TourSelect} from "@src/pages/Tours/TourChoise/components/TourSelect/TourSelect";
import {LoadMore} from "@src/components/LoadMore/LoadMore";
import {useSelector} from "react-redux";

import {
    selectTripsResultAmount,
} from "@src/store/ducks/trips/selectors";

export const TourChoise = () => {
    const amount = useSelector(selectTripsResultAmount)

    return (
        <div>
            <Title title={'Таблица туров'} svgComponent={TourSvg} ammountTours={amount}></Title>
            <TourSelect/>
            <TourTable/>
            <LoadMore/>
        </div>
    )
}

/*https:\/\/api.anitatour.by\/api\/v1\/orders\/get?page=2*/