import React, {FC, useState} from "react";
import cn from 'classnames'
 import {NotificationTypeArr} from "@src/store/ducks/user/types";
import style from '@src/components/Notification/Notification.module.scss'
import {NotificationClose} from "@src/components/AllSvg/NotificationClose";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {deleteNotificationFunc} from "@src/store/ducks/user/thunks";

type DataProps = {
    data: NotificationTypeArr
};

export const NotificationElem: FC<DataProps> = ({data}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [deleteControl, serDeleteControl] = useState(false)
    return (
        <div
            className={cn(style['notification-elem'], !data.read && style['notification-elem__active'], deleteControl && style['notification-elem__delete-active'])}
            onClick={() => {

            }}>
            <div className={cn(style['notification-elem__delete'])} onClick={() => {
                serDeleteControl(true)
                setTimeout(() => {
                    dispatch(deleteNotificationFunc(data.id))
                }, 500)

            }}>
                <NotificationClose/>
            </div>
            <div className={style['notification-elem__created']}>
                {data.createdAt}
            </div>
            <div className={style['notification-elem__title']}>
                {data.title}
            </div>
            <div className={style['notification-elem__date']}>
                <span>{data.dateFrom}</span>
                <span>-</span>
                <span>{data.dateTo}</span>
            </div>
            <div className={cn(style['notification-elem__description'])}
                 dangerouslySetInnerHTML={{__html: data.description}}/>
        </div>
    )
}
