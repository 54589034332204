import axios, {Method, ResponseType} from 'axios'
import {keysToCamelCase, keysToSnakeCase} from '@src/lib/utils'
import {getCookie} from "@src/store/ducks/auth/thunks";

type CallApiType = {
  url: string
  data?: object
  params?: object
  headers?: Record<string, string>
  responseType?: ResponseType
  method: Method
}

const callApi = async ({
                         url, method, data, params = {}, headers = {}, responseType,
                       }:CallApiType) => {
  const token = `Bearer ${getCookie('token')}`
  const baseURL = process.env.REACT_APP_API_URL || 'https://api.anitatour.by';
  const config = {
    url,
    method,
    params: keysToSnakeCase(params),
    data: headers['Content-Type'] ? data : keysToSnakeCase(data),
    responseType,
    baseURL: baseURL,
    headers: {
      ...axios.defaults.headers.common,
      'Content-Type': 'application/json',
      Authorization: token,
      ...headers,
    },

  }
  try {
    const response = await axios(config)
    return keysToCamelCase(response.data)
  } catch (err: any) {
/*    // @ts-ignore
    if (err?.response?.status === 401) {
      await getNewToken()
      return new Promise((resolve, reject) => {
        axios.request(err.config).then((response) => {
          resolve(keysToCamelCase(response.data))
        }).catch((error) => {
          if (err?.response?.status === 401) {
          }
          reject(error)
        })
      })
      // todo добавить логаут при 401*/
    }
/*    return Promise.reject(err)
  }*/
}

export const api = {
  async get<T = any>(url: string, params?: object, headers?: Record<string, string>, responseType?: ResponseType): Promise<T> {
    return callApi({
      url, params, headers, responseType, method: 'GET',
    })
  },
  async post<T = any>(url: string, data?: object, params?: object, headers?: Record<string, string>): Promise<T> {
    return callApi({
      url, data, params, headers, method: 'POST',
    })
  },
  async put<T = any>(url: string, data?: object, params?: object, headers?: Record<string, string>): Promise<T> {
    return callApi({
      url, data, params, headers, method: 'PUT',
    })
  },
  async del<T = any>(url: string, params?: object, headers?: Record<string, string>): Promise<T> {
    return callApi({
      url, params, headers, method: 'DELETE',
    })
  },
}
