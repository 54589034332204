import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TripsResultArrElemType} from "@src/store/ducks/trips/types";
import {
    BusPlacesArr,
    OrdersHistoryElemType,
    OrdersHistoryFilesType,
    OrdersHistoryType,
    OrdersParamsType
} from "@src/store/ducks/orders/types";
import {selectHistoryOrdersDate, selectOrderStatuses} from "@src/store/ducks/orders/selectors";
import {useSelector} from "react-redux";
import {string} from "yup";


export const initialState = {
    passengersQuantity: {} as any || '',
    currentChoosenTourData: {} as TripsResultArrElemType,
    currentBusPlaces: [],
    currentChoosenBusPlaces: [],
    ordersParams: {} as OrdersParamsType,
    stepsState: {
        step1: false,
        step2: true,
        step3: true,
        step4: true,
        step5: true,
    },
    historyData: {
        orders: {
            currentPage: 1,
            data: [],
        },
        hasMore: false,
        nextPage: null,
    } as OrdersHistoryType,
    orderId: 0,
    previousStatus: 0 as number | any, // Для хранения предыдущего статуса
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setPassengersQuantity(state, {payload}: PayloadAction<any>) {
            state.passengersQuantity = payload
        },
        setCurrentChoosenTourData(state, {payload}: PayloadAction<TripsResultArrElemType>) {
            state.currentChoosenTourData = payload
        },
        setCurrentBusPlaces(state, {payload}: PayloadAction<any>) {
            state.currentBusPlaces = payload
        },
        setCurrentChoosenBusPlaces(state, {payload}: PayloadAction<any>) {
            state.currentChoosenBusPlaces = payload
        },
        setOredrsParams(state, {payload}: PayloadAction<OrdersParamsType>) {
            state.ordersParams = payload
        },
        setStepsState(state) {
            state.stepsState = initialState.stepsState;
        },
        setStepsState1(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step1 = payload
        },
        setStepsState2(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step2 = payload
        },
        setStepsState3(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step3 = payload
        },
        setStepsState4(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step4 = payload
        },
        setStepsState5(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step5 = payload
        },

        setHistoryData(state, {payload}: PayloadAction<{result:any, status:number | null}>) {
            const { result, status } = payload;

            // Если статус в payload отличается от предыдущего статуса, очищаем массив и заполняем заново
            if (status !== state.previousStatus) {
                // Обнуляем массив и заполняем новыми данными
                state.historyData.orders.data = [...result.orders.data];
            } else {
                // Если статус не изменился, добавляем новые элементы, если их нет
                result.orders.data.forEach((newHistory: any) => {
                    if (!state.historyData.orders.data.some(history => history.id === newHistory.id)) {
                        state.historyData.orders.data.push(newHistory);
                    }
                });
            }

            // Обновляем остальные данные
            state.historyData.nextPage = result.orders.nextPage;
            state.historyData.hasMore = result.orders.hasMore;

            // Сохраняем текущий статус в state для сравнения с следующим
            state.previousStatus = status;
        },
        setOrderId(state, {payload}: PayloadAction<any>) {
            state.orderId = payload
        },
        setHistoryDataFiles(state, {payload}: PayloadAction<{ orderId: string, files: OrdersHistoryFilesType[] }>) {
            const {orderId, files} = payload;
            const orderToUpdate = state.historyData.orders.data.find(order => String(order.id) === orderId);
            if (orderToUpdate) {
                orderToUpdate.files = files;
            }
        },
        setHistoryDataFilesNew(state, {payload}: PayloadAction<{ orderId: string, file: OrdersHistoryFilesType }>) {
            const {orderId, file} = payload;
            const orderToUpdate = state.historyData.orders.data.find(order => String(order.id) === orderId);
            if (orderToUpdate) {
                orderToUpdate.files.push(file);
            }
        },
        setHistoryNewArrCity(state, {payload}: PayloadAction<{ orderId: string, cityArr: any }>) {
            const {orderId, cityArr} = payload;
            const orderToUpdate = state.historyData.orders.data.find(order => String(order.id) === orderId);
            if (orderToUpdate) {
                orderToUpdate.citiesArrHistory = cityArr;
            }
        },
        setDeleteHistoryData(state, { payload }: PayloadAction<{ orderId: string, touristId: string }>) {
            const { orderId, touristId } = payload;

            const currentOrder = state.historyData.orders.data.find(item => String(item.id) === orderId);

            if (currentOrder) {
                const index = currentOrder.tourists.findIndex(item => String(item.id) === touristId);

                if (index !== -1) {
                    currentOrder.tourists.splice(index, 1);
                }
            }
        },
        setHistoryNewBusPlaces(state, {payload}: PayloadAction<{orderId?: number,  result:any}>) {
            const {orderId, result } = payload;
            const orderToUpdate = state.historyData.orders.data.find(order => order.id === orderId);
            if (orderToUpdate) {
                orderToUpdate.busPlaces = result;
            }
        },
        setHistoryUpdate(state, { payload }: PayloadAction<{ orderId?: string, result: any }>) {
            const { orderId, result } = payload;

            // Найдем индекс объекта, который нужно обновить
            const orderIndex = state.historyData.orders.data.findIndex(order => String(order.id) === orderId);

            if (orderIndex !== -1) {
                // Создадим новый массив с обновленным объектом
                state.historyData.orders.data = [
                    ...state.historyData.orders.data.slice(0, orderIndex), // все до обновляемого
                    { ...state.historyData.orders.data[orderIndex], ...result.order }, // новый объект
                    ...state.historyData.orders.data.slice(orderIndex + 1), // все после
                ];
            }
        },

        setHistoryDeleteFiles(state, {payload}: PayloadAction<{ orderId: string, filesId: string }>) {
            const {orderId, filesId} = payload;
            const orderFilesToUpdate = state.historyData.orders.data.find(order => String(order.id) === orderId);
            if (orderFilesToUpdate) {
                const index = orderFilesToUpdate.files.findIndex(item => String(item.id) === filesId);
                if (index !== -1) {
                    orderFilesToUpdate.files.splice(index, 1);
                }
            }
        },
    },
})

export type OrdersStateType = typeof initialState

export const {
    setPassengersQuantity,
    setCurrentChoosenTourData,
    setCurrentBusPlaces,
    setCurrentChoosenBusPlaces,
    setOredrsParams,
    setStepsState,
    setStepsState1,
    setStepsState2,
    setStepsState3,
    setStepsState4,
    setStepsState5,
    setHistoryData,
    setOrderId,
    setHistoryDataFiles,
    setHistoryDataFilesNew,
    setHistoryNewArrCity,
    setDeleteHistoryData,
    setHistoryNewBusPlaces,
    setHistoryUpdate,
    setHistoryDeleteFiles
} = ordersSlice.actions
export const ordersReducer = ordersSlice.reducer
