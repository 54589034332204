import React, {Dispatch, FC, SetStateAction, useState} from "react";
import style from '@src/pages/History/HistoryDetails/HistoryDetails.module.scss'
import {Place} from "@src/components/AllSvg/Place";
import {OrdersHistoryPlaces} from "@src/store/ducks/orders/types";
import {useDispatch, useSelector} from "react-redux";
import {selectRoomTypes} from "@src/store/ducks/orders/selectors";
import cn from "classnames";
import {Button} from "@src/components/Button/Button";
import {ChangeHistory} from "@src/components/AllSvg/ChangeHistory";
import {DeleteHistory} from "@src/components/AllSvg/DeleteHistory";
import {HistoryDeleteElemModal} from "@src/pages/History/HistoryOrderElem/HistoryDeleteElemModal";
import {getHistoryNewArrCityAsync} from "@src/store/ducks/orders/thunks";
import {AppDispatch} from "@src/store/store";

type Step2Props = {
    data: {
        state: string,
        fio: string,
        fioLat: string,
        birthDate: string,
        passportExpiryDate: string,
        passportSeriesAndNumber: string,
        phoneNumber: string,
        roomType: string,
        hotel: string,
        gender: string,
        fromCity: string,
        citizenCategory: string,
        nationality: string,
        bus_slug: string,
        id: number
    }
    index: number,
    places: OrdersHistoryPlaces
    vehicleType: string,
    setChangedUser: Dispatch<SetStateAction<number | null>>,
    orderId: number,
    changedUser : number | null,
    arrayLength: number
}


// TRAIN FLOW: add busType
export const HistoryOrderElem: FC<Step2Props> = ({
                                                     data,
                                                     index,
                                                     places,
                                                     vehicleType,
                                                     setChangedUser,
                                                     orderId, changedUser, arrayLength
                                                 }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const dataRoomTypesFromApi = useSelector(selectRoomTypes)

    const orderData = {
        fio: data.fio,
        fioLat: data.fioLat,
        birthDate: data.birthDate,
        passportExpiryDate: data.passportExpiryDate,
        passportSeriesAndNumber: data.passportSeriesAndNumber,
        phoneNumber: data.phoneNumber,
        gender: data.gender,
        fromCity: data.fromCity,
        citizenCategory: data.citizenCategory,
        nationality: data.nationality,
        place: places.place,
        roomType: places.roomType,
        hotel: places.hotel,
    }

    const roomTypeTitle = dataRoomTypesFromApi.find(item => item.id === +orderData.roomType);

    return (
        <div className={style['order-elem']}>
            <div className={style['order-elem-title']}>
                Турист №{index + 1} ({orderData.citizenCategory})
                <div className={style.buttonsWrapper}>
                    <Button className={cn(style.buttonHistory, changedUser && changedUser !== index && style.blockButton)} svgComponent={ChangeHistory}
                            onClickFunc={() => {
                                setChangedUser(index)
                                dispatch(getHistoryNewArrCityAsync(String(orderId)))
                            }}/>
                    <Button className={cn(style.buttonHistory, changedUser && changedUser !== index && style.blockButton, arrayLength < 2 && style.blockButton)} svgComponent={DeleteHistory} onClickFunc={() => {
                        setShowDeleteModal(true)
                    }}/>
                    {showDeleteModal &&
                        <HistoryDeleteElemModal title={`Турист №${index + 1} (${orderData.citizenCategory})`}
                                                setShowDeleteModal={setShowDeleteModal} orderId={orderId}
                                                touristId={data.id}/>}
                </div>
            </div>
            <div className={style['order-elem-list']}>
                <div className={style['order-elem-item']}>
                    <span>ФИО на русском</span>
                    <span>{orderData.fio}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>ФИО атиницей</span>
                    <span>{orderData.fioLat}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Дата рождения</span>
                    <span>{orderData.birthDate}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Серия и номер паспорта</span>
                    <span>{orderData.passportSeriesAndNumber}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Дата окончания паспорта</span>
                    <span>{orderData.passportExpiryDate}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Номер телефона туриста</span>
                    <span>{orderData.phoneNumber}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Категория номера в отеле</span>
                    <span>{roomTypeTitle?.title}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Категория гражданина (-ки)</span>
                    <span>{orderData.citizenCategory}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Пол туриста</span>
                    <span>{orderData.gender}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Гражданство</span>
                    <span>{orderData.nationality}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Отель</span>
                    <span>{orderData.hotel}</span>
                </div>
            </div>
            {/* TRAIN FLOW: hide if */}
            <div className={cn(style['place-wrapper'], vehicleType !== 'bus' ? 'general--hidden' : '')}>
                <div className={style.place}>
                    <span>{orderData.place}</span>
                    <Place/>
                </div>
                <span>Выбранное место</span>
            </div>
        </div>
    )
}
