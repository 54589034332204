import {RootState} from '@src/store/store'
import {UserStateType} from "@src/store/ducks/user/reducer";


export const selectUser = (state: RootState): UserStateType => state.user

export const selectUserInfo = (state: RootState) => selectUser(state).user

export const selectAvailablePlaces = (state: RootState) => selectUser(state).availablePlaces

export const selectCode = (state: RootState) => selectUser(state).code
export const selectNotification = (state: RootState) => selectUser(state).notification

export const selectNotificationData = (state: RootState) => selectUser(state).notificationData



