import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {Form, Formik} from "formik";
import {HistoryPlaceChange} from "@src/pages/History/HistoryPlacesChange/HistoryPlaceChange";
import {OrdersHistoryElemType} from "@src/store/ducks/orders/types";
import {Button} from "@src/components/Button/Button";
import {updateHistoryPlacesAsync} from "@src/store/ducks/orders/thunks";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@src/store/store";


type HistoryPlaceChangeFormProps = {
    data: OrdersHistoryElemType,
    setShowChosePlaces:Dispatch<SetStateAction<boolean>>
};


export const HistoryPlaceChangeForm:FC<HistoryPlaceChangeFormProps> = ({data, setShowChosePlaces}) => {
    const dispatch = useDispatch<AppDispatch>()
    let currentPlaces = data.places.map(item => {
        return item.place
    })
    const initialValues = useMemo(() => ({
        useFreePlaces: data.useFreePlaces,
        selectedPlaces: currentPlaces,
    }), [])

    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    // Проверка на изменения в форме, чтобы кнопка "Сохранить" показывалась только при изменении значений
    useEffect(() => {
        setInitialFormValues(initialValues);
    }, [initialValues]);

    const isFormChanged = JSON.stringify(initialFormValues) !== JSON.stringify(initialValues);


    return (
        <div className={''}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    setShowChosePlaces(false)
                    dispatch(updateHistoryPlacesAsync(String(data.id),values))
                }}
                enableReinitialize={true}
            >
                {({dirty }) => {
                    return (
                        <Form>
                            <div>
                                <HistoryPlaceChange data={data} name={'selectedPlaces'} setShowChosePlaces={setShowChosePlaces} isFormChanged={isFormChanged || dirty}/>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
