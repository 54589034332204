import React, {useMemo, FC, Dispatch, SetStateAction} from "react";
import style from "@src/pages/Tours/TourFormalization/TourFormalization.module.scss";
import {Form, Formik} from "formik";

import {TouristForm} from "@src/pages/Tours/TourFormalization/components/steps/Step2/TouristForm";
import {Button} from "@src/components/Button/Button";
import {OrdersHistoryPlaces} from "@src/store/ducks/orders/types";
import {useDispatch, useSelector} from "react-redux";
import {selectRoomTypes} from "@src/store/ducks/orders/selectors";
import * as Yup from "yup";
import {validateForm} from "@src/messages/validateForm";
import {updateHistoryAsync} from "@src/store/ducks/orders/thunks";
import {AppDispatch} from "@src/store/store";

type HistoryChangeProps = {
    changedData: any,
    index?: number,
    places?: OrdersHistoryPlaces
    vehicleType?: string
    setChangedUser: Dispatch<SetStateAction< number | null>>,
    cityArr : any,
    orderId: number,
    changedUser: number
}

export const HistoryChange: FC<HistoryChangeProps> = ({changedData,
                                                          places,
                                                          setChangedUser,
                                                          cityArr, orderId, changedUser}) => {
    const dispatch = useDispatch<AppDispatch>()
    const dataRoomTypesFromApi = useSelector(selectRoomTypes)
    let roomTypeTitle= ''
    let fromCityTitle= ''
    let hotel= ''
    let foundRoomType: any = ''
    if (places) {
        foundRoomType = dataRoomTypesFromApi.find(item => item.id === +places.roomType);
        roomTypeTitle = foundRoomType ? foundRoomType.title : '';
        fromCityTitle = places.fromCity
        hotel = places.hotel
    }
    const validationSchema = useMemo(() => (
        Yup.object().shape({
            data: Yup.object().shape({
                tourists: Yup.array().of(Yup.object().shape({
                    fio: Yup.string().required(validateForm.required),
                    birthDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
                    passportExpiryDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
                    passportSeriesAndNumber: Yup.string().required(validateForm.required),
                    phoneNumber: Yup.string().matches(/^\+\d{3}\s\(\d{2}\)\s\d{7}$/, 'Неверный формат номера телефона').required(validateForm.required),
                }))
            }),
            selectsArr: Yup.array().of(Yup.object().shape({
                roomType: Yup.mixed().required(validateForm.required),
                gender: Yup.mixed().required(validateForm.required),
                /* citizenCategory: Yup.mixed().required(validateForm.required),*/
                // fromCity: Yup.mixed().required(validateForm.required),
            })),
        })
    ), [])


    const initialValues = useMemo(() => ({
        selectsArr: [...Array(1)].map(() => ({
            roomType: roomTypeTitle ? {label: roomTypeTitle, value: places?.roomType} : '',
            gender: changedData.gender ? {label: changedData.gender, value: changedData.gender} : '',
            citizenCategory: changedData.citizenCategory ? {
                label: changedData.citizenCategory,
                value: changedData.citizenCategory
            } : '',
            fromCity: fromCityTitle ? {
                label: fromCityTitle,
                value: fromCityTitle
            } : '',
        })),
        data: {
            tourists: [Array(1)].map(() => ({
                id: changedData.id,
                fio: changedData.fio ? changedData.fio : '',
                fioLat: changedData.fioLat ? changedData.fioLat : '',
                birthDate: changedData.birthDate ? changedData.birthDate : '',
                passportExpiryDate: changedData.passportExpiryDate ? changedData.passportExpiryDate : '',
                passportSeriesAndNumber: changedData.passportSeriesAndNumber ? changedData.passportSeriesAndNumber : '',
                phoneNumber: changedData.phoneNumber ? changedData.phoneNumber : '',
                roomType: foundRoomType? foundRoomType.id : '',
                hotel: hotel? hotel : '',
                gender: changedData.gender? changedData.gender: '',
                fromCity:  fromCityTitle ? fromCityTitle : '',
                citizenCategory: changedData.citizenCategory? changedData.citizenCategory : '',
                nationality: changedData.nationality ? changedData.nationality : '',
            }))
        }
    }), [])

    return (
        <div className={style.HistoryChange}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    dispatch(updateHistoryAsync(String(orderId),values.data.tourists[0]));
                    console.log(values)
                    setChangedUser(null)
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {() => {
                    return (
                        <Form>
                            <TouristForm namePart={`data.tourists[0]`} nameSelectArr={'selectsArr[0]'} cityArr = {cityArr}
                                         num={1} changedUser={changedUser}/>
                            <Button htmlType={"submit"} className={'btn btn-second'}>
                                Сохранить
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}