import {ChangeEvent, FC, ReactNode, useState} from 'react'
import cn from 'classnames'

import style from '@src/components/common/CheckBoxFormik/CheckBoxFormik.module.scss'
import {useFormikContext} from "formik";
import {ConfirmationModal} from "@src/pages/Tours/TourFormalization/components/steps/Step3/ConfirmationModal";

type CheckBoxFormikProps = {
    name: string
    type?: string
    label?: string | ReactNode
    className?: string
    value?: string | number
    onChange?: (value?: any) => void
    checked?: boolean
    randomFilling?: any
}

export const CheckBoxFormik: FC<CheckBoxFormikProps> = ({
                                                            className,
                                                            name,
                                                            type = 'checkbox',
                                                            label,
                                                            value,
                                                            checked,
                                                        }) => {
    const {setFieldValue} = useFormikContext();
    const [showModal, setShowModal] = useState(false); // Для отображения модалки
    const [tempChecked, setTempChecked] = useState(checked); // Временное состояние для чекбокса

    const handleClick = () => {
        if (tempChecked) {
            // Если чекбокс уже выбран (checked = true), сразу сбрасываем его
            setFieldValue(name, 0);
            setTempChecked(false);
        } else {
            // Если чекбокс не выбран, показываем модалку
            setShowModal(true);
        }
    };

    // Подтвердить изменения (изменить состояние чекбокса)
    const handleConfirm = () => {
        setFieldValue(name, 1); // Устанавливаем значение в 1 (чекбокс активирован)
        setTempChecked(true); // Обновляем временное состояние чекбокса
        setShowModal(false); // Скрыть модалку
    };

    // Отменить изменения (вернуться к исходному состоянию чекбокса)
    const handleCancel = () => {
        setShowModal(false); // Просто скрыть модалку
    };

    return (
        <div className={cn(style.box, className)}>
            <label className={style.custom_checkbox}>
                <input
                    className={style.custom_checkbox}
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleClick}
                    checked={tempChecked} // Используем временное состояние
                />
                <span>
        {' '}
                    {label}
      </span>
            </label>

            {/* Если модалка должна быть показана, отображаем её */}
            {showModal && (
                <ConfirmationModal
                    onClose={handleCancel}
                    onConfirm={handleConfirm}
                    message="При выборе данного режима произойдет заполнение мест случайным образом"
                />
            )}
        </div>
    )
}
