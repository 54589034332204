import React, {Dispatch, FC, SetStateAction} from "react";
import style from '@src/pages/History/HistoryDetails/HistoryDetails.module.scss'
import {Button} from "@src/components/Button/Button";
import cn from "classnames";
import {deleteHistoryAsync} from "@src/store/ducks/orders/thunks";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@src/store/store";

type HistoryDeleteElemModalProps = {
    setShowDeleteModal: Dispatch<SetStateAction<boolean>>,
    title:string,
    orderId:number,
    touristId:number
}
export const HistoryDeleteElemModal: FC<HistoryDeleteElemModalProps> = ({setShowDeleteModal, title, orderId,touristId}) => {
    const dispatch = useDispatch<AppDispatch>()
    return (
        <div className={cn(style.historyDeleteElemModal,style.historyDeleteElemModal__open )}>
            <div className={style.historyDeleteElemModal__popup}>
                <div className={style['historyDeleteElemModal__popup-body']}>
                    <div className={style['historyDeleteElemModal__popup-wrapper']}>
                        <div className={style['historyDeleteElemModal__popup-title']}>
                            <p>Вы уверены, что хотите удалить</p>
                            <p>{title}</p>
                        </div>
                        <div className={style['historyDeleteElemModal__popup-btnBlock']}>
                            <Button className={cn(style['historyDeleteElemModal__popup-btn'], style['historyDeleteElemModal__popup-btn-yes'])} onClickFunc={()=> {
                                dispatch(deleteHistoryAsync(String(orderId),String(touristId)))
                                setShowDeleteModal(false)
                            }}>
                                Да
                            </Button>
                            <Button className={cn(style['historyDeleteElemModal__popup-btn'], style['historyDeleteElemModal__popup-btn-back'])} onClickFunc={() => {
                                setShowDeleteModal(false)
                            }}>Назад</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}