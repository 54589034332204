import {AppDispatch, RootState} from '@src/store/store'
import {authApi} from '@src/api/auth-api'
import {logoutAC, setBackError, setIsAuth, setIsLoading, setUserEmail,} from '@src/store/ducks/auth/reducer'
import {keysToCamelCase} from '@src/lib/utils'
import {fetchMe} from "@src/store/ducks/user/thunks";
import {getHistoryAsinc, getParamsAsinc} from "@src/store/ducks/orders/thunks";
import {getTripsDataAsinc} from "@src/store/ducks/trips/thunks";


export const login = (emailUser: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true))
    dispatch(setBackError(null))
    try {
        const result = await authApi.login(emailUser, password)
        setCookie('token', result.accessToken)
        setCookie('user-id', String(result.userId))
        dispatch(setIsAuth(true))
        dispatch(fetchMe(result.userId))
        dispatch(getTripsDataAsinc())
        dispatch(getParamsAsinc())
        dispatch(getHistoryAsinc(1, result.userId, 1))

    } catch (err: any) {
        dispatch(setBackError(keysToCamelCase('Пользователь либо пароль не верны')))
        dispatch(setIsAuth(false))
    }
}

export const logout = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {isAuth} = getState()
    try {
        dispatch(logoutAC())
        if (isAuth.isAuth) {
            await authApi.logout()
            deleteCookie('token');
            deleteCookie('user-id');
        }
    } catch (err) {
        console.error(err)
    }
}

export const checkEmailAsinc = (email: string, navigate: any) => async (dispatch: AppDispatch) => {
    try {
        const result = await authApi.checkEmail(email)
        if (result) {
            dispatch(sendCodeAsinc(email, navigate))
        } else {
            dispatch(setBackError(keysToCamelCase('Недействительный адрес электронной почты')))
        }
    } catch (err: any) {
        dispatch(setBackError(keysToCamelCase('Недействительный адрес электронной почты')))
    }
}

export const sendCodeAsinc = (email: string, navigate: any) => async (dispatch: AppDispatch) => {
    try {
        const result = await authApi.sendCode(email)
        dispatch(setUserEmail(email))
        navigate("/confirm-password");
    } catch (err: any) {
        dispatch(setBackError(keysToCamelCase('Недействительный адрес электронной почты')))
    }
}

export const verifyCodeAsinc = (email: string, code: string, navigate: any) => async (dispatch: AppDispatch) => {
    try {
        const result = await authApi.verifyCode({email: email, type: 'registration', code: +code})
        navigate("/newPassword");

    } catch (err: any) {
        dispatch(setBackError(keysToCamelCase('Недействительный адрес электронной почты')))
    }
}


export const changePasswordAsinc = (email: string, password: string, newPassword: string, navigate:any) => async (dispatch: AppDispatch) => {
    try {
        const result = await authApi.changePassword(
            email,
            password,
            newPassword,
        )
        navigate("/profile");
    } catch (err: any) {
        /*        dispatch(setBackError(keysToCamelCase('Недействительный адрес электронной почты')))*/
    }
}

export const changePasswordNewAsinc = (code :number , email: string, password: string, newPassword: string, navigate: any) => async (dispatch: AppDispatch) => {
    try {
        const result = await authApi.changePasswordNew(
            code,
            email,
            password,
            newPassword,
        )
        navigate("/login");

    } catch (err: any) {
        /*        dispatch(setBackError(keysToCamelCase('Недействительный адрес электронной почты')))*/
    }
}


export const setCookie = (name: string, value: string, date = "day") => {
    const currentDate = new Date();
    let expDate;
    if (date === "month") {
        expDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    } else if (date === "year") {
        expDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
    } else {
        expDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    document.cookie = `${name}=${value}; secure; expires=${expDate.toUTCString()}; path=/`;
    // document.cookie = `${name}=${value}; expires=${expDate.toUTCString()}; path=/`; For Http connection
};

export const getCookie = (name: string) => {
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};
const deleteCookie = (name: string) => {
    document.cookie = name + '=; Max-Age=-1;';
}