import React from "react";

export const NotificationClose = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4189 21.25L13.2598 12.75M13.2606 21.25L21.4198 12.75" stroke="currentColor" strokeWidth="1.44"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M30.9402 16.9999C30.9402 9.17588 24.8512 2.83325 17.3402 2.83325C9.82916 2.83325 3.74023 9.17588 3.74023 16.9999C3.74023 24.8239 9.82916 31.1666 17.3402 31.1666C24.8512 31.1666 30.9402 24.8239 30.9402 16.9999Z"
                stroke="currentColor" strokeWidth="1.44"/>
        </svg>
    )
}