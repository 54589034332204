import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ApiResponse, NotificationData, UserAllType, UserType} from "@src/store/ducks/user/types";

export const initialState = {
  user: {} as UserType,
  availablePlaces : null,
  code:'',
  notification: false,
  notificationData: {
    hasMore: false,
    notificationArr: [],
    notificationsNewCount: null,
  } as NotificationData,
} as UserAllType
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state , { payload }: PayloadAction<ApiResponse>) {
      state.user = payload.user
    },
    setAvailablePlaces(state , { payload }: PayloadAction<any>) {
      state.availablePlaces = payload
    },
    setCode(state , { payload }: PayloadAction<string>) {
      state.code = payload
    },
    setNotification(state , { payload }: PayloadAction<boolean>) {
      state.notification = payload
    },
    setNotificationData(state , { payload }: PayloadAction<NotificationData>) {
      state.notificationData.hasMore = payload.hasMore;
      state.notificationData.notificationsNewCount = payload.notificationsNewCount;
      payload.notificationArr.forEach(newNotification => {
        if(!state.notificationData.notificationArr.some(notification => newNotification.id === notification.id)) {
          state.notificationData.notificationArr.push(newNotification);
        }
      });
    },
    setNotificationArrChange(state , { payload }: PayloadAction<any>) {
      state.notificationData.notificationArr = payload
    },
    setDeleteNotification(state , { payload }: PayloadAction<number>) {
      const index = state.notificationData.notificationArr.findIndex(item => item.id === payload);
      // Если элемент найден, удаляем его
      if (index !== -1) {
        state.notificationData.notificationArr.splice(index, 1);
      }
    },
  },
})
export type UserStateType = typeof initialState
export const {
  setUser,
  setAvailablePlaces,
  setCode,
  setNotification,
  setNotificationData,
  setNotificationArrChange,
  setDeleteNotification

} = userSlice.actions
export const userReducer = userSlice.reducer
