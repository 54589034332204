import React, {FC, useEffect, useState} from "react";
import style from '@src/pages/History/HistoryFiles/HistoryFiles.module.scss'
import cn from "classnames";
import {Button} from "@src/components/Button/Button";
import {
    changeHistoryFilesTitleAsync,
    deleteHistoryFilesAsync,
    downloadImagesHistoryAsync,
    getPdfActAsinc
} from "@src/store/ducks/orders/thunks";
import {Download} from "@src/components/AllSvg/Download";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {OrdersHistoryFilesType} from "@src/store/ducks/orders/types";

type HistoryFilesElemProps = {
    data: OrdersHistoryFilesType,
    orderId:number
};


export const HistoryFilesElem: FC<HistoryFilesElemProps> = ({data, orderId}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [currentTitle, setCurrentTitle] = useState(data.title ? data.title : '')

    const changeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentTitle(event.target.value);
    };

    return (
        <div
            className={cn(style.historyFilesElem)}>
            <div className={style.historyFilesElem__title}>
                <div className={style['historyFilesElem__title-input']}>
                    <input
                        type="text"
                        value={currentTitle}
                        onChange={changeTitle}
                        className={''}
                    />
                </div>
                {currentTitle !== data.title && <div className={style.historyFilesElem__saveTitle} onClick={()=> {
                    dispatch(changeHistoryFilesTitleAsync(String(orderId),String(data.id),currentTitle))
                }}>Сохранить</div>}
            </div>
            <div className={cn(style['TableElem-block'], style.downloadFile)}>
                <Button onClickFunc={() => dispatch(downloadImagesHistoryAsync(String(orderId),String(data.id),data.title))} svgComponent={Download}
                        className={style.downloadBtn}>Скачать файл</Button>
                <Button onClickFunc={()=> {
                    dispatch(deleteHistoryFilesAsync(String(orderId), String(data.id)))
                }} className={style.deleteBtn}>Удалить файл</Button>
            </div>
        </div>
    )
}


