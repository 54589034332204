import React, {FC, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import '@src/components/common/Select/SelectCustom.scss';
import {useField, useFormikContext} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import style from "@src/components/common/FieldFormik/FieldFormik.module.scss";
import {setPassengersQuantity, setStepsState} from "@src/store/ducks/orders/reducer";
import {
    filterTripsDataCity,
    filterTripsDataCountry,
    filterTripsDataToursCity,
    filterTripsDataToursCountry
} from "@src/store/ducks/trips/reducer";
import cn from "classnames";
import {selectOrdersPassengersQuantity} from "@src/store/ducks/orders/selectors";


interface OptionProps {
    value?: string;
    label?: string;
    id?: number,
    title?: string,
    countryId?: number,
    cityId?: number
}

interface DataProps {
    data: OptionProps[];
    heading?: string;
    placeholder?: string;
    className?: string
    name: string
    labelTitle?: string
    type?: string
    num?: number
}

interface GroupedOption {
    label?: string;
    options?: OptionProps[];
}

export const SelectCustom: FC<DataProps> = ({
                                                data,
                                                heading,
                                                placeholder,
                                                className,
                                                name,
                                                labelTitle,
                                                type,
                                                num
                                            }) => {
    const groupedOptions: GroupedOption = {
        label: heading,
        options: data,
    };


    const {setFieldValue} = useFormikContext()
    const [field, meta] = useField(name)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])
    const passengersQuantity = useSelector(selectOrdersPassengersQuantity)
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if(Object.keys(passengersQuantity).length !== 0) {
            setSelectedOption(passengersQuantity)
        }
    }, [])

    const handleChange = (selectedOption: any) => {
        setIsFocused(true)
        if (type === 'step1') {
            dispatch(setPassengersQuantity(selectedOption))
            dispatch(setStepsState())
        }
        setSelectedOption(selectedOption)
        setFieldValue(name, selectedOption);
        console.log(selectedOption)
        console.log(name)
        console.log('num', num)
        switch (name) {
            case 'country':
                setFieldValue('filterPropsData.countryId', selectedOption.value);
                const id = data.filter(item => item.value === selectedOption.value).map(item => item.id)[0];
                dispatch(filterTripsDataCountry(id))
                break
            case 'city':
                setFieldValue('filterPropsData.cityId', selectedOption.value);
                const countryId = data.filter(item => item.value === selectedOption.value).map(item => item.countryId)[0];
                const cityId = data.filter(item => item.value === selectedOption.value).map(item => item.id)[0];
                dispatch(filterTripsDataCountry(countryId))
                dispatch(filterTripsDataCity(cityId))
                break
            case 'tour':
                setFieldValue('filterPropsData.tourId', selectedOption.value);
                const countryTourId = data.filter(item => item.value === selectedOption.value).map(item => item.countryId
                )[0];
                const cityTourId = data.filter(item => item.value === selectedOption.value).map(item => item.cityId
                )[0];
                dispatch(filterTripsDataToursCountry(countryTourId))
                dispatch(filterTripsDataToursCity(cityTourId))
                break
            case `selectsArr[${num && num - 1}].roomType`:
                setFieldValue(`data.tourists[${num && num - 1}].roomType`, selectedOption.value);
                break
            case `selectsArr[${num && num - 1}].gender`:
                setFieldValue(`data.tourists[${num && num - 1}].gender`, selectedOption.value);
                break
            case `selectsArr[${num && num - 1}].citizenCategory`:
                setFieldValue(`data.tourists[${num && num - 1}].citizenCategory`, selectedOption.value);
                break
            case `selectsArr[${num && num - 1}].fromCity`:
                setFieldValue(`data.tourists[${num && num - 1}].fromCity`, selectedOption.value);
                break
            case 'sourceRegistration':
                setFieldValue('data.sourceRegistration', selectedOption.value);
                break
        }

    }
    return (
        <div className={className}>
            <label>
                {labelTitle && <div className={style.label}>{labelTitle}</div>}
                {type !== 'step1' ?
                    <Select
                        options={[groupedOptions]}
                        className={cn(isError && 'react-select-container-error', field.value ? 'react-select-container react-select-container-choosed' : 'react-select-container')}
                        classNamePrefix="react-select"
                        placeholder={placeholder}
                        onChange={handleChange}
                        value={field.value}
                        noOptionsMessage={() => 'Нет вариантов'}
/*                                                menuIsOpen={true}*/
                    /> :
                    <Select
                        options={[groupedOptions]}
                        className={cn(selectedOption ? 'react-select-container react-select-container-choosed ' : 'react-select-container')}
                        classNamePrefix="react-select"
                        placeholder={placeholder}
                        onChange={handleChange}
                        value={selectedOption}
                        noOptionsMessage={() => 'Нет вариантов'}
                        /*  menuIsOpen={true}*/
                    />}
                {isError && <div className={style.error}>{meta.error}</div>}
            </label>
        </div>
    );
};
