import {CheckBoxFormik} from "@src/components/common/CheckBoxFormik/CheckBoxFormik";
import style from '@src/pages/Tours/TourFormalization/components/steps/Step3/Step3.module.scss'
import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import cn from 'classnames'
import {AppDispatch} from "@src/store/store";
import {useFormikContext} from "formik";
import {Place} from "@src/components/AllSvg/Place";
import {Bus49} from "@src/components/AllSvg/Buses/Bus49";
import {Bus51} from "@src/components/AllSvg/Buses/Bus51";
import {Bus53} from "@src/components/AllSvg/Buses/Bus53";
import {OrdersHistoryElemType} from "@src/store/ducks/orders/types";
import {Button} from "@src/components/Button/Button";

type HistoryPlaceChangeProps = {
    name: string,
    data: OrdersHistoryElemType,
    setShowChosePlaces: Dispatch<SetStateAction<boolean>>,
    isFormChanged: boolean
}

let arrPlaces: string[] = [];
export const HistoryPlaceChange: FC<HistoryPlaceChangeProps> = ({name, data, setShowChosePlaces, isFormChanged}) => {
    const svgRef = useRef<any>(null);
    const {getFieldProps, setFieldValue} = useFormikContext();
    // const currentFreePlaces = useSelector(selectCurrentFreePlaces)
    const dispatch = useDispatch<AppDispatch>()
    let placesData: any = data.busPlaces
    const placesAmount = data.places.length
    const chosePlace = getFieldProps('useFreePlaces').value;
    let currentPlaces = data.places.map(item => {
        return item.place
    })
    const scrollRef = useRef<any>();
    const [scrollValue, setScrollValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100); // Начальное значение max
    const [firstShow, setFirstShow] = useState(false);
    const [alert, setAlert] = useState(false)

    let elem = getFieldProps('selectedPlaces').value

    const sortPlacesArray = (collection: any) => {
        const sortedNodes = Array.from(collection).sort((a: any, b: any) => {
            const idA = a.id || a.getAttribute('id');
            const idB = b.id || b.getAttribute('id');

            // Разделяем ID на числовую и буквенную часть
            const matchA = idA.match(/(\d+)([A-Za-z]*)/);
            const matchB = idB.match(/(\d+)([A-Za-z]*)/);

            // Извлекаем числовые части
            const numA = parseInt(matchA[1], 10);
            const numB = parseInt(matchB[1], 10);

            // Сравниваем числовую часть в обратном порядке
            if (numB !== numA) {
                return numB - numA;
            }

            // Если числа одинаковые, сравниваем буквенные части
            const letterA = matchA[2] || '';
            const letterB = matchB[2] || '';
            return letterB.localeCompare(letterA);
        });
        return sortedNodes
    }


    const handleScroll = (e: any) => {
        setScrollValue(e.target.value)
    }

    const handleScrollImageWrapper = () => {
        if (scrollRef.current) {
            const scrollValueCurrent = scrollRef.current.scrollLeft;
            setScrollValue(scrollValueCurrent)
            const scrollValueMax = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
            setMaxValue(scrollValueMax)
        }
    }

    const changeStatus = (e: any) => {
        setAlert(false)
        const activeElem = e.target.closest('g')
        if (!activeElem.classList.contains('busy') && !activeElem.classList.contains('system')) {
            let index = arrPlaces.indexOf(activeElem.id)
            if (index >= 0) {
                arrPlaces.splice(index, 1);
                activeElem.classList.remove(style.choosen)
            } else if (arrPlaces.length < +placesAmount) {
                arrPlaces.push(String(activeElem.id));
                activeElem.classList.add(style.choosen)
            }
        }
        setFieldValue(name, arrPlaces)
    }

    function randomArrayFilling() {
        setAlert(false)
        if (svgRef.current) {
            const allPlaces = svgRef.current.querySelectorAll('.placeBus');
            const sortedArr = sortPlacesArray(allPlaces)
            sortedArr.forEach((item: any) => {
                if (arrPlaces.length < +placesAmount) {
                    let index = arrPlaces.indexOf(item.id)
                    if (index < 0 && item.classList.contains(style.free)) {
                        arrPlaces.push(item.id)
                    }
                }
            })
        }
        setFieldValue(name, arrPlaces)
    }

    useEffect(() => {
        if (!firstShow) {
            setFirstShow(true);
        } else {
            if (chosePlace) {
                setFieldValue(name, [])
                arrPlaces = []
                randomArrayFilling()
            } else {
                // Другие действия
            }
        }
    }, [chosePlace]);

    useEffect(() => {
        if (svgRef.current) {
            const allPlaces = svgRef.current.querySelectorAll('.placeBus');
            allPlaces.forEach((element: any) => {
                if (placesData) {
                    if (Object.keys(placesData).includes(element.id)) {
                        if (!arrPlaces.includes(element.id)) {
                            if (placesData[element.id] === 'system') {
                                element.classList.add(style.system)
                            } else if (placesData[element.id] === true) {
                                element.classList.add(style.free)
                            } else if (currentPlaces.includes(element.id)) {
                                element.classList.add(style.free)
                            } else {
                                element.classList.add(style.busy)
                            }
                        } else {
                            element.classList.add(style.free)
                        }
                    }
                }
            });
        }
    }, [svgRef.current, placesData]);

    useEffect(() => {
        const allPlaces = svgRef.current.querySelectorAll('.placeBus');
        allPlaces.forEach((element: any) => {
            element.classList.remove(style.choosen)
            const index = elem.indexOf(element.id);
            if (index > -1) {
                element.classList.add(style.choosen)
                if (!arrPlaces.includes(element.id)) {
                    arrPlaces.push(element.id)
                }
            }
        })
    }, [elem])


    useEffect(() => {
        if (scrollRef.current) {
            setMaxValue(scrollRef.current.scrollWidth - scrollRef.current.clientWidth)
        }
        scrollRef.current.scrollLeft = scrollValue
    }, [scrollValue])

    useEffect(() => {
        console.log(arrPlaces, 'arrPlaces')
    }, [arrPlaces]);


    return (
        <div className={style['step-3']}>
            <div className={style.subtitle}>Услуга выбора места, является платной и
                составляет {+data.tour.commissionAmountPlace} белорусских рублей.
            </div>
            <CheckBoxFormik
                name="useFreePlaces"
                type="checkbox"
                checked={chosePlace}
                label={(
                    <span>
                    Без выбора места
                </span>
                )}
            />
            <div className={cn(style.scheme, chosePlace && style.mask)}>
                <div className={style.schemeWrapper}>
                    <div ref={scrollRef} className={style.imageWrapper} onScroll={handleScrollImageWrapper}>
                        <div className={style.imageBus}>
                            {data.trip.bus.type === '1' &&
                                <Bus49 ref={svgRef} changeStatus={changeStatus}/>}
                            {data.trip.bus.type === '2' &&
                                <Bus51 ref={svgRef} changeStatus={changeStatus}/>}
                            {data.trip.bus.type === '3' &&
                                <Bus53 ref={svgRef} changeStatus={changeStatus}/>}
                        </div>
                    </div>
                    <div className={style.slidecontainer}>
                        <input type="range"
                               min='0'
                               max={maxValue}
                               value={scrollValue} onChange={handleScroll}/>
                    </div>
                </div>
                <div className={style.customPlaces}>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorWhite)}>
                            <Place/>
                        </div>
                        <span>Место свободно</span>
                    </div>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorRed)}>
                            <Place/>
                        </div>
                        <span>Место занято</span>
                    </div>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorGray)}>
                            <Place/>
                        </div>
                        <span>Служебное место</span>
                    </div>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorBlue)}>
                            <Place/>
                        </div>
                        <span>Место выбрано вами</span>
                    </div>
                </div>
            </div>
            {alert && <div className={style.alert}> Заполните все места согласно количеству билетов</div>}
            <div className={cn(style.info, chosePlace && style.mask)}>
                <p>Типовая схема* расположения мест в автобусе</p>
                <p>*Расположение кресел и дверей в автобусе указано примерно и может отличаться в зависимости от модели,
                    марки автобуса и общего количества посадочных мест.</p>
            </div>

                <div className={style.buttonsWrapper}>
                    {isFormChanged &&
                    <Button htmlType={"submit"} className={'btn-second'} onClickFunc={(event:any)=> {
                        if(arrPlaces.length < placesAmount && !chosePlace) {
                            // event.preventDefault();
                            setAlert(true)
                        } else {
                            arrPlaces = []
                        }
                    }}>
                        Сохранить
                    </Button>
                    }
                    <Button htmlType={"button"} className={cn('btn-second', style.buttonCLose)} onClickFunc={() => {
                    setFieldValue('selectedPlaces', currentPlaces)
                    setShowChosePlaces(false)
                    arrPlaces = []
                }}>Закрыть / Отменить изменения</Button>
                </div>
        </div>
    )
}
