import React, {useEffect, useState} from 'react';

import {RoutesProject} from './routes/routes'
import {getCookie} from "@src/store/ducks/auth/thunks";
import {fetchMe} from "@src/store/ducks/user/thunks";
import {getTripsDataAsinc, getTripsResultDataAsinc} from "@src/store/ducks/trips/thunks";
import {getHistoryAsinc, getParamsAsinc} from "@src/store/ducks/orders/thunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {selectIsAuth} from "@src/store/ducks/auth/selectors";
import {setIsAuth} from "@src/store/ducks/auth/reducer";
import {selectUserInfo} from "@src/store/ducks/user/selectors";


export const App = () => {
    const dispatch = useDispatch<AppDispatch>()
    const isAuth = useSelector(selectIsAuth)
    const user = useSelector(selectUserInfo)

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!isAuth) {
            const cookieToken = getCookie('token')
            const cookieId = getCookie('user-id')
            if (cookieToken && cookieId) {
                dispatch(setIsAuth(true))
                dispatch(fetchMe(+cookieId))
                dispatch(getTripsDataAsinc())
/*                dispatch(getTripsResultDataAsinc())*/
                dispatch(getParamsAsinc())
                dispatch(getHistoryAsinc(1, +cookieId, 1))
            }
            setIsReady(true);
        }
    }, [dispatch]);


    return (
        <div style={{height: '100%'}}>
            {isReady && (
                <div className="App">
                    <RoutesProject/>
                </div>
            )}
        </div>
    )
}

