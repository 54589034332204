import React, {FC} from 'react';
// import style from '@src/pages/History/HistoryDetails/HistoryDetails.module.scss'
import style from '@src/pages/Tours/TourFormalization/components/steps/Step3/Step3.module.scss'
import cn from "classnames";
import {Button} from "@src/components/Button/Button";

type ModalProps = {
    onClose: () => void;
    onConfirm: () => void;
    message: string;
};

export const ConfirmationModal: FC<ModalProps> = ({onClose, onConfirm, message}) => {
    return (
        <div className={cn(style.historyDeleteElemModal, style.historyDeleteElemModal__open)}>
            <div className={style.historyDeleteElemModal__popup}>
                <div className={style['historyDeleteElemModal__popup-body']}>
                    <div className={style['historyDeleteElemModal__popup-wrapper']}>
                        <div className={style['historyDeleteElemModal__popup-title']}>
                            <p>{message}</p>
                        </div>
                        <div className={style['historyDeleteElemModal__popup-btnBlock']}>
                            <Button
                                className={cn(style['historyDeleteElemModal__popup-btn'], style['historyDeleteElemModal__popup-btn-yes'])}
                                onClickFunc={() => {
                                    onConfirm()
                                }}>
                                Продолжить
                            </Button>
                            <Button
                                className={cn(style['historyDeleteElemModal__popup-btn'], style['historyDeleteElemModal__popup-btn-back'])}
                                onClickFunc={() => {
                                    onClose()
                                }}>Отменить</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

