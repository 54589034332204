import React, {useEffect, useRef, useState} from 'react'
import cn from 'classnames'
import {NotificationClose} from "@src/components/AllSvg/NotificationClose";
import {NotificationElem} from "@src/components/Notification/NotificationElem";
import {NotificationTypeArr} from "@src/store/ducks/user/types";
import style from '@src/components/Notification/Notification.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {selectNotification, selectNotificationData} from "@src/store/ducks/user/selectors";
import {setNotification, setNotificationArrChange} from "@src/store/ducks/user/reducer";
import {AppDispatch} from "@src/store/store";
import arrowTop from "@src/images/arrow-notification.svg"
import okSvg from "@src/images/ok.svg"
import notificationIcon from "@src/images/notificationIcon.svg"
import {loadNotificationFunc} from "@src/store/ducks/user/thunks";


export const Notification = () => {
    const dispatch = useDispatch<AppDispatch>()
    const notificationData = useSelector(selectNotificationData)
    const [openSelect, setOpenSelect] = useState(false)
    const [choosenElem, setChoosenElem] = useState(true)
    const notification = useSelector(selectNotification)
    const [lastNotification, setLastNotification] = useState(0)
    const [firstNotification, setFirstNotification] = useState(0)
    const [fetching, setFetching] = useState(false)
    let hasMore = notificationData.hasMore
    let notificationArr = notificationData.notificationArr
    const notificationListRef = useRef<HTMLDivElement | null>(null);

    function enableScroll() {
        document.body.style.paddingRight = `0`
    }

    useEffect(() => {
        if (notification) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'auto'
        }
    }, [notification]);

    const scrollHandler = () => {
        if (!notificationListRef.current) return;

        const scrollTop = notificationListRef.current.scrollTop;
        const totalHeight = notificationListRef.current.scrollHeight;
        const windowHeight = notificationListRef.current.clientHeight;

        if (totalHeight - (scrollTop + windowHeight) <= 100 && !fetching) {
            setFetching(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            // Логика подгрузки данных, если произошел скролл
            if (fetching && hasMore) {
                try {
                    // Проверка, чтобы не инициировать подгрузку данных, если id последнего уведомления уже было использовано
                    if(choosenElem) {
                        await dispatch(loadNotificationFunc({ last_id: firstNotification, 'order_by_date' : 'desc' }));
                    } else {
                        await dispatch(loadNotificationFunc({ last_id: lastNotification, 'order_by_date' : 'asc' }));
                    }
                    // После завершения запроса сбрасываем флаг загрузки
                    setFetching(false); // Сбрасываем флаг после выполнения запроса
                } catch (error) {

                } finally {

                }
            }
        };

        fetchData();
    }, [fetching, lastNotification, hasMore, dispatch]);

    // Attach scroll event listener to the notification list div
    useEffect(() => {
        if (notificationListRef.current) {
            notificationListRef.current.addEventListener('scroll', scrollHandler);
        }

        // Cleanup the event listener when component unmounts or updates
        return () => {
            if (notificationListRef.current) {
                notificationListRef.current.removeEventListener('scroll', scrollHandler);
            }
        }
    }, [fetching]);


    useEffect(() => {
        // Проверяем, изменился ли массив уведомлений
        if (notificationArr.length > 0) {
            const newLastNotification = notificationArr.reduce((max, obj) => obj.id > max ? obj.id : max, 0);
            const newFirstNotification = notificationArr.reduce((min, obj) => obj.id < min ? obj.id : min, Infinity);
            if (newLastNotification !== lastNotification) {
                setLastNotification(newLastNotification);
            }
            if (newFirstNotification !== lastNotification) {
                setFirstNotification(newFirstNotification);
            }
        }
    }, [notificationArr, lastNotification, firstNotification]);  // Обновляем lastNotification только если данные изменились


    return (
        <div className={cn(style.notification, notification && style.notification__active)}>
            <div className={style.notification__top}>
                <div className={style.notification__title}>
                    <img src={notificationIcon} alt='select-arrow'/>
                    <span>Уведомления</span>
                </div>
                <div className={style.notification__close} onClick={() => {
                    dispatch(setNotification(!notification))
                    enableScroll()
                }}>
                    <NotificationClose/>
                </div>
            </div>
            <div className={cn(style.notification__select, openSelect && style['notification__select-active'])}
                 onClick={() => {
                     setOpenSelect(!openSelect)
                 }}>
                <div className={style.notification__titleSelect}>
                    <span>Сортировка</span>
                    <img src={arrowTop} alt='select-arrow'/>
                </div>
                <div className={style.notification__openSelect}>
                    <div className={style.notification__shoosenSelect} onClick={() => {
                        setChoosenElem(true)
                        dispatch(setNotificationArrChange([]))
                        dispatch(loadNotificationFunc({'order_by_date' : 'desc'}))
                    }}>
                        {choosenElem && <img src={okSvg} alt='select-arrow'/>}
                        <span>Сначала новые</span>
                    </div>
                    <div className={style.notification__shoosenSelect} onClick={() => {
                        setChoosenElem(false)
                        dispatch(setNotificationArrChange([]))
                        dispatch(loadNotificationFunc({'order_by_date' : 'asc'}))
                    }}>
                        {!choosenElem && <img src={okSvg} alt='select-arrow'/>}
                        <span>Сначала старые</span>
                    </div>
                </div>
            </div>
            <div className={style.notification__list} ref={notificationListRef}>
                {notificationArr.map((item: NotificationTypeArr) => (
                    <NotificationElem key={item.id} data={item}/>))}
            </div>
        </div>
    )
}


