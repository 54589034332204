import React, {useMemo, useState} from "react";
import {Title} from "@src/components/Title/Title";
import {DecorSvg} from "@src/components/AllSvg/DecorSvg";
import {TabsStep} from "@src/pages/Tours/TourFormalization/components/tabs-step/TabsStep";
import {Direction} from "@src/pages/Tours/TourFormalization/components/Enum";

import style from '@src/pages/Tours/TourFormalization/TourFormalization.module.scss'
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {getVehicleTypeFromVehicleSlug} from "@src/customFunctions/General";
import {Step1} from "@src/pages/Tours/TourFormalization/components/steps/Step1/Step1";
import {Step2} from "@src/pages/Tours/TourFormalization/components/steps/Step2/Step2";
import {Step3} from "@src/pages/Tours/TourFormalization/components/steps/Step3/Step3";
import {Step4} from "@src/pages/Tours/TourFormalization/components/steps/Step4/Step4";
import {Step5} from "@src/pages/Tours/TourFormalization/components/steps/Step5/Step5";

import {useDispatch, useSelector} from "react-redux";
import {selectCurrentChoosenTourData, selectOrdersPassengersQuantity} from "@src/store/ducks/orders/selectors";
import {AppDispatch} from "@src/store/store";
import {selectAvailablePlaces, selectUserInfo} from "@src/store/ducks/user/selectors";
import moment from "moment/moment";
import {setSuccessPage, setTourChoiseOpen, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import {postOrder} from "@src/store/ducks/orders/thunks";
import cn from "classnames";
import {NavLink} from "react-router-dom";
import logo from "@src/images/logo.svg";
import burgerClose from "@src/images/burger-close.svg";
import {validateForm} from "@src/messages/validateForm";
import {setBackError} from "@src/store/ducks/auth/reducer";


export const TourFormalization = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [step, setStep] = useState<Direction>(Direction.Step1)
    const passengersQuantity = useSelector(selectOrdersPassengersQuantity)
    const chosenTour = useSelector(selectCurrentChoosenTourData)
    const chosenUser = useSelector(selectUserInfo)
    const availablePlaces = useSelector(selectAvailablePlaces)
    const [openMobileSteps, setOpenMobileStep] = useState(false)
    const orderNumber = OrderNumber()
    const currentDate = moment().format('DD.MM.YYYY');
    const user = useSelector(selectUserInfo)

    const vehicleType = getVehicleTypeFromVehicleSlug(chosenTour.bus.slug)

    function setTimeDateFmt(s: any) {// однозначные цифры составляют десять цифр
        return s < 10 ? '0' + s : s;
    }

    function OrderNumber() {
        const now = new Date()
        let month = now.getMonth() + 1
        let day = now.getDate()
        let hour = now.getHours()
        let minutes = now.getMinutes()
        let seconds = now.getSeconds()
        month = setTimeDateFmt(month)
        day = setTimeDateFmt(day)
        hour = setTimeDateFmt(hour)
        minutes = setTimeDateFmt(minutes)
        let orderCode = (day + month.toString() + hour + seconds).toString();
        return orderCode;
    }


    const validationSchema1 = useMemo(() => (
        Yup.object().shape({
            sourceRegistration: Yup.mixed().required(validateForm.required),
            data: Yup.object().shape({
                tourists: Yup.array().of(Yup.object().shape({
                    fio: Yup.string().required(validateForm.required),
                    birthDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
                    passportExpiryDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
                    passportSeriesAndNumber: Yup.string().required(validateForm.required),
                    phoneNumber: Yup.string().matches(/^\+\d{3}\s\(\d{2}\)\s\d{7}$/, 'Неверный формат номера телефона').required(validateForm.required),
                }))
            }),
            selectsArr: Yup.array().of(Yup.object().shape({
                roomType: Yup.mixed().required(validateForm.required),
                gender: Yup.mixed().required(validateForm.required),
/*                citizenCategory: Yup.mixed().required(validateForm.required),*/
                fromCity: Yup.mixed().required(validateForm.required),
            })),
        })
    ), [])
    const validationSchema2 = useMemo(() => (
        Yup.object().shape({
            sourceRegistration: Yup.mixed().required(validateForm.required),
            data: Yup.object().shape({
                tourists: Yup.array().of(Yup.object().shape({
                    fio: Yup.string().required(validateForm.required),
                    birthDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
                }))
            }),
        })
    ), [])

    const initialValues = useMemo(() => ({
        selectsArr: [...Array(passengersQuantity.value ? +passengersQuantity.value : 0)].map(() => ({
        })),
        passengersQuantity: '',
        sourceRegistration: user.roleId === 2 ? 'Агент' : '',
        fromCity: '',
        roomType: '',
        gender: '',
        citizenCategory: '',
        data: {
            number: orderNumber,
            status: '2',
            startDate: chosenTour.dateStart,
            endDate: chosenTour.dateEnd,
            source: "#description_link_to_other_pageApi",
            userId: chosenUser.id,
            tourId: chosenTour.tourId,
            tripId: chosenTour.id,
            busId: chosenTour.busId,
            availablePlaces: availablePlaces,
            holdPlaces: +passengersQuantity.value,
            applicationDate: currentDate,
            sourceRegistration: user.roleId === 2 ? 'AGENT' : '',
            additionalExcursions: '',
            finalPriceByn: '',
            finalFirstPartPriceByn: '',
            finalSecondPartPriceByn: '0',
            finalPriceUsd: 0,
            createdAt: currentDate,
            updatedAt: currentDate,
            useFreePlaces: true,
            selectedPlaces: [],
            tourists: [...Array(passengersQuantity.value ? +passengersQuantity.value : 0)].map(() => ({
                state: '0',
                fio: '',
                fioLat: '',
                birthDate: '',
                passportExpiryDate: '',
                passportSeriesAndNumber: '',
                phoneNumber: '',
                roomType: '',
                hotel: '',
                gender: '',
                fromCity: '',
                citizenCategory: '',
                nationality: '',
            }))
        }
    }), [passengersQuantity])

    return (
        <div className={style.tourFormalization}>
            <Title title={'Оформление заявки'} svgComponent={DecorSvg}></Title>
            <div className={cn(style['tabsStep-mobile'], openMobileSteps ? style['steps-mobile-active'] : '')}>
                <div className="container">
                    <div className={style['mobile-steps-inner']}>
                        <div className={style['steps-mb-logo']}>
                            <NavLink
                                to="/tours"
                                onClick={() => {
                                    dispatch(setTourFormalisationOpen(false));
                                    dispatch(setSuccessPage(false));
                                    dispatch(setTourChoiseOpen(true));
                                }}>
                                <div className={style.logo}>
                                    <img
                                        src={logo}
                                        alt="logo"
                                    />
                                </div>
                            </NavLink>
                        </div>
                        <div className={style.burger}>
                            <div onClick={() => setOpenMobileStep(false)} className={style['burger-close']}>
                                <img
                                    src={burgerClose}
                                    alt="burger-close"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style['tabsStep-mobile-title']}>Этапы оформления</div>
                    <TabsStep vehicleType={vehicleType} step={step} setStep={setStep} className={style['tabsStep-mobile-wrapper']}
                              openMobileSteps={openMobileSteps} setOpenMobileStep={setOpenMobileStep}/>
                </div>
            </div>
            <TabsStep vehicleType={vehicleType} step={step} setStep={setStep} openMobileSteps={openMobileSteps}
                      setOpenMobileStep={setOpenMobileStep}/>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    dispatch(setBackError(null))
                    dispatch(postOrder(values.data));
                }}
                validationSchema={chosenUser.roleId === 2 ? validationSchema1 : validationSchema2}
                enableReinitialize={true}
            >
                {() => {
                    return (
                        <Form>
                            <div>
                                {step === Direction.Step1 &&
                                    <Step1 name='passengersQuantity' title={chosenTour.title}
                                           date={`${chosenTour.dateStart} -${chosenTour.dateEnd} `} step={step}
                                           setStep={setStep}/>}
                                {step === Direction.Step2 &&
                                    <Step2 vehicleType={vehicleType} quantity={passengersQuantity.value} step={step}
                                           setStep={setStep}/>}
                                {step === Direction.Step3 &&
                                    <Step3 step={step}
                                           setStep={setStep} name='data.selectedPlaces'/>}
                                {step === Direction.Step4 &&
                                    <Step4 vehicleType={vehicleType} step={step}
                                           setStep={setStep}/>}
                                {step === Direction.Step5 &&
                                    <Step5 vehicleType={vehicleType}/>}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
