import {FC, ReactElement} from 'react'
import {Header} from "@src/components/Header/Header";
import {HeaderMobile} from "@src/components/HeaderMobile/HeaderMobile";
import {Notification} from "@src/components/Notification/Notification";

type PrivateRouteProps = {
    Component: ReactElement
}

export const PrivateRoute: FC<PrivateRouteProps> = ({Component}) => {

    return (
        <div className='privateRoute'>
            <div className='headers'>
                <Header/>
                <HeaderMobile/>
                <Notification/>
            </div>
            <main>
                <div className={'container'}>
                    {Component}
                </div>
            </main>
        </div>
    )
}
